import axios from 'axios'
import axiosRetry from 'axios-retry'
import { TripStatus, MAX_API_CALL_RETRIES, API_CALL_DELAY } from '../globalConstants'

type Location = {
  name: string
  id: string
  localTimeZone: string
}

export const apiClient = axios.create({
  baseURL: 'https://7ffr9sv897.execute-api.eu-west-2.amazonaws.com',
})

if (process.env.NODE_ENV !== 'test') {
  axiosRetry(apiClient, {
    retries: MAX_API_CALL_RETRIES,
    retryDelay: API_CALL_DELAY,
  })
}

export const makeApiCall = async (
  endpoint: string,
  method: 'GET' | 'POST' | 'PUT' = 'GET',
  reqData?: Record<string, unknown>
) => {
  const response = await apiClient.request({
    method,
    url: endpoint,
    params: reqData,
  })
  return response.data
}

apiClient.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {}
    config.headers.Authorization = 'Bearer ' + sessionStorage.getItem('jwtToken')
    return config
  },
  (error) => Promise.reject(error)
)

type FetchLocationsReqDto = { type: 'EMPLOYMENT' | 'DESTINATION' | 'HOME' }

type FetchLocationsResDto = Location[]

export const fetchLocations = async (req: FetchLocationsReqDto): Promise<FetchLocationsResDto> => {
  const res = await makeApiCall('/locations', 'GET', req)
  return res.locations
}

type FetchAirportsResDto = Location[]

export const fetchAirports = async (req: FetchLocationsReqDto): Promise<FetchAirportsResDto> => {
  const res = await makeApiCall('/airports', 'GET', req)
  return res.airports
}

type CalculateDurationReqDto = {
  outFlightStartTimestamp: string
  outDurationOfFlight: number
  outAirportId: string
  employmentLocationId: string
  outLocationId: string
  inFlightStartTimestamp?: string
  inDurationOfFlight?: number
  inAirportId?: string
  inLocationId?: string
}

type CalculateDurationResDto = {
  outTotalTravelDuration: number
  outTotalOooDuration: number
  inTotalTravelDuration: number
  inTotalOooDuration: number
}

export const calculateDuration = async (
  req: CalculateDurationReqDto,
  UUID: string
): Promise<CalculateDurationResDto> => {
  const res = await makeApiCall(`/ooo-reimbursement/${UUID}/calculation`, 'GET', req)
  return res
}

type UpdateTripReqDto = CalculateDurationReqDto & {
  timestamp: string
  id: string
  status: TripStatus
  email: string
}

export const updateTrip = async (req: UpdateTripReqDto, UUID: string) => {
  await makeApiCall(`/ooo-reimbursement/${UUID}`, 'PUT', req)
}
